import React, { ChangeEvent, ReactElement, ReactNode } from "react";
import { Box, Select } from "@chakra-ui/react";
import { FieldWrapper } from "./FieldWrapper";
import { useFormikContext } from "formik";

export function SelectField({
  options,
  name,
  label,
  callback = (value: string) => {},
  borderColor,
  borderHoverColor,
  fontSize = "1.1rem",
  textAlign = "center",
  fontFamily = "linotte",
  height,
  placeholder,
  isDisabled,
}: Props): ReactElement {
  const formikProps = useFormikContext();

  const optionList: ReactNode = options.map((value: string) => (
    <option value={value} key={value}>
      {value}
    </option>
  ));

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    formikProps.setFieldValue(name, value);
    callback(value);
  };

  return (
    <Box fontFamily="galdeano">
      <FieldWrapper name={name} label={label}>
        <Select
          onChange={onChange}
          borderColor={borderColor}
          _focus={{ borderColor: borderHoverColor }}
          _hover={{ borderColor: borderHoverColor }}
          borderRadius="md"
          borderWidth="2px"
          fontSize={fontSize}
          value={formikProps.values[name]}
          height={height}
          textAlign={textAlign}
          fontFamily={fontFamily}
          placeholder={placeholder}
          bg="white"
          isDisabled={isDisabled}
        >
          {optionList}
        </Select>
      </FieldWrapper>
    </Box>
  );
}

interface PropsNoStyle {
  options: string[];
  name: string;
  label?: string;
  fontSize?: string;
  placeholder?: string;
  callback?: (value: string) => void;
}

interface Props extends PropsNoStyle {
  borderColor: string;
  borderHoverColor: string;
  fontFamily?: string;
  height?: string;
  textAlign?: string;
  isDisabled?: boolean;
}
