import { Client, Index, Match, Paginate } from "faunadb";
import { PaginatedResponse } from "../Types";
import { getByIndexes, indexMatch, IndexSearch, q } from "./common";
import { getUserReferencebyUserId } from "./user";

export async function getNPCCollected(client: Client, userId: string, paginateOptions?: any): Promise<PaginatedResponse<any>> {
  const userReference = await getUserReferencebyUserId(client, userId);
  const response = await client.query(
    q.Map(q.Paginate(q.Match(q.Index("npcs_by_user"), userReference), paginateOptions), q.Lambda(["ref"], q.Get(q.Var("ref"))))
  );
  // Isn't this the same as returning response with a cast?
  return {
    data: response["data"],
    after: response["after"],
    before: response["before"],
  };
}

export async function getNPCNames(client: Client): Promise<PaginatedResponse<string>> {
  return await client.query(Paginate(Match(Index("npcs_names_by_is_published"), true), { size: 100000 }));
}

export async function getNPCsPublished(client: Client, publishedOptions: PublishedNPCOptions, paginateOptions: any): Promise<PaginatedResponse<any>> {
  const { name, archetype, template } = publishedOptions;
  const indexSearches: IndexSearch[] = [];
  const indexMain = "npcs_by_is_published_sort_priority";
  const indexByName = "npcs_by_name_sort_priority";
  const indexByArchetype = "npcs_by_archetype_sort_priority";
  const indexByTemplate = "npcs_by_template_sort_priority";

  indexSearches.push({ index: indexMain, value: true });
  if (name && name !== " ") {
    indexSearches.push({ index: indexByName, value: name });
  }
  if (template && template !== " ") {
    indexSearches.push({ index: indexByTemplate, value: template });
  }
  if (archetype && archetype !== " ") {
    indexSearches.push({ index: indexByArchetype, value: archetype });
  }

  return await getByIndexes(client, indexSearches, paginateOptions);
}

export interface PublishedNPCOptions {
  name?: string;
  template?: string;
  archetype?: string;
}
