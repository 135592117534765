import { Button, Grid, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Label } from "../styled/Text";
import { isDesktopHook } from "../../Hooks";
import { InputField } from "../common/form_fields/InputField";
import { archetypeOptions } from "../../constants/npc/Archetypes";
import { SelectField } from "../common/form_fields/SelectField";
import { templateList } from "../../constants/npc/Templates";
import { CardGrid } from "./CardGrid";
import { SuggestionField } from "../common/form_fields/SuggestionField";
import { useApiClient } from "../../context/ApiClient";
import { getNPCNames } from "../../api/npc";

export function CardGridNPC({ title, apiMethod, showFilters, dataConverter, contentType, collection }: GridProp) {
  const colorProp = {
    primaryColor: "npc.primary",
    secondaryColor: "npc.secondary",
  };

  const isDesktop = isDesktopHook();

  const [nameOptions, setNameOptions] = useState<string[]>([]);

  const client = useApiClient().getClient();

  useEffect(() => {
    getNPCNames(client).then((resp) => {
      setNameOptions(resp.data);
    });
  }, []);

  return (
    <CardGrid
      colorProp={colorProp}
      title={title}
      apiMethod={apiMethod}
      showFilters={showFilters}
      dataConverter={dataConverter}
      contentType={contentType}
      collection={collection}
    >
      <Grid w="95vw" gap={6} templateColumns={{ lg: "repeat(10, 1fr)}", md: "repeat(3, 1fr)" }}>
        <GridItem colSpan={3}>
          <Label text="Template" />
          <SelectField
            borderColor="npc.secondary"
            borderHoverColor="npc.secondaryHover"
            name="template"
            options={templateList.map((t) => t.value)}
            fontSize="1.25rem"
            fontFamily="galdeano"
            height="5.5vh"
            placeholder=" "
          />
        </GridItem>
        <GridItem colSpan={3}>
          <Label text="Archetype" />
          <SelectField
            borderColor="npc.primary"
            borderHoverColor="npc.primaryHover"
            name="archetype"
            options={archetypeOptions}
            fontSize="1.25rem"
            fontFamily="galdeano"
            height="5.5vh"
            placeholder=" "
          />
        </GridItem>
        <GridItem colSpan={3}>
          <Label text="Name" />
          <SuggestionField name="name" borderColor="npc.primary" borderHoverColor="npc.primaryHover" options={nameOptions} height="5.4vh" />
        </GridItem>
        <GridItem colSpan={1}>
          <Button
            m={isDesktop ? "4vh 0 0 0" : "0 0 0 32vw"}
            background="npc.primary"
            _hover={{ background: "npc.primaryHover" }}
            color="white"
            type="submit"
          >
            Search
          </Button>
        </GridItem>
      </Grid>
    </CardGrid>
  );
}
