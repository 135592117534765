import React, { ReactElement } from "react";

import { Box } from "@chakra-ui/react";
import { getNPCsPublished } from "../../api/npc";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { NPCDataConverter } from "../../components/list_homebrew/DataConverters";
import { COLLECTIONS } from "../../constants/General";
import { useApiClient } from "../../context/ApiClient";
import { CardGridNPC } from "../../components/list_homebrew/CardGridNpc";

export default function Creations(): ReactElement {
  const apiClient = useApiClient();

  return (
    <Box minH="100vh" backgroundColor="npc.background">
      <MyNavbar />
      <CardGridNPC
        title="Explore NPCs BETA"
        showFilters={true}
        apiMethod={(filterValues, paginationOptions) => getNPCsPublished(apiClient.getClient(), filterValues, paginationOptions)}
        dataConverter={NPCDataConverter}
        contentType="public"
        collection={COLLECTIONS.NPCs}
      />
    </Box>
  );
}
